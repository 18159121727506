import { jwtDecode } from "jwt-decode";

export const setToken = (token) => {
    if (token) {
        localStorage.setItem("jwt_token", token);
    } else {
        localStorage.removeItem("jwt_token");
    }
};

export const getToken = () => {
    return localStorage.getItem("jwt_token");
};

export const isAuthenticated = () => !!getToken();

export const initializeAuth = () => {
    const token = getToken();
    if (token) {
        setToken(token);
    }
};

export const getDecodedToken = () => {
    const token = getToken();
    return token ? jwtDecode(token) : null;
};

export const hasRole = (requiredRole) => {
    const decodedToken = getDecodedToken();

    if (!decodedToken) return false;

    const roleHierarchy = ["user", "admin"];
    const userRoleIndex = roleHierarchy.indexOf(decodedToken.role);
    const requiredRoleIndex = roleHierarchy.indexOf(requiredRole);

    return userRoleIndex >= requiredRoleIndex;
};

export const logout = () => {
    setToken(null);
};
